div.Success {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-left: 3em;
    padding-right: 3em;
  }


  /* div.Success>div {
    margin-top: 1em;
  } */